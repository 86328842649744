<script>
    import { required, minLength } from "vuelidate/lib/validators";
    import Customers from '@/services/Customers';
    import Multiselect from 'vue-multiselect';
    import validationMessages from '@/components/validations'
    export default {
        props: {
            modalData: {
                type: Object,
                default: () => ({}),
            },
        },
        components:{
            Multiselect,  validationMessages
        },
        validations: {
            filterInput: {
                email: {
                    required,
                }
            }
        },
        data() {
            return {
                csrf_token: localStorage.getItem('csrf_token'),
                source: 'dashboard',
                showModal: false,
                tryingToEdit: false,
                submitted: false,
                email : '',
                title: "Customers",
                filterInput: {
                    email: "",
                },
                customerData: [],
                filter: null,
                isLoading: false
            };
        },
        methods: {
            async getCustomersAutocomplete(query) {
                this.customerData = [];
                if (query.length > 0) {
                    this.isLoading = true;
                    try {
                        const response = await Customers.getCustomersByFilter(`email=${query}`);
                        const rawData = response.data.data;
                        this.customerData = rawData
                            .map(item => ({
                                text: item.email,
                                id: item.customer_id
                            }))
                            .filter((value, index, self) =>
                                self.findIndex(x => x.id === value.id) === index
                            );
                    } catch (error) {
                        console.error("Error fetching customers:", error);
                    } finally {
                        this.isLoading = false;
                    }
                }
            },

            async addBusinessMember() {
                const company_id = this.modalData.company_id;

                this.tryingToEdit = true;
                this.submitted = true;
                this.$v.$touch()

                if (this.$v.$invalid) {
                    this.tryingToEdit = false;
                    return;
                } else {

                    try {
                        const customerId = this.filterInput.email ? this.filterInput.email.id : null;

                        if (customerId === null) {
                            return;
                        }

                        const data = {
                            company_id: company_id,
                            customer_id: customerId,
                            pull: true
                        };

                        await Customers.addBusinessMember(data);
                        this.successmsg("Members has been saved");
                        this.closeModal();
                        this.refreshData();
                    } catch (error) {
                        this.error = error.response.data.error ? error.response.data.error : "";
                        this.failedmsg(this.error);
                    } finally {
                        this.tryingToEdit = false;
                    }
                }
            },
            clearAll () {
                this.customerData = []
            },
            resetProps(){
                this.email= ''
                this.customerData = []
                this.filterInput.email = []
                this.submitted = false
            },
            refreshData() {
                this.$emit('onRefresh')
            },
            closeModal() {
                this.showModal = false;
            },
        }
    };
</script>
<template>
    <b-modal v-model="showModal" id="add_company_members" @hidden='resetProps()' title="Add Members" title-class="font-18" centered>
        <form @submit.prevent="addBusinessMember">
            <b-form-group>
                <label class="typo__label" for="ajax">Email</label>
                <multiselect v-model="filterInput.email"
                                label="text"
                                track-by="id"
                                placeholder="Type to search email"
                                :options="customerData"
                                :multiple="false"
                                :searchable="true"
                                :loading="isLoading"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="true"
                                :options-limit="300"
                                :max-height="600"
                                :show-no-results="false"
                                :hide-selected="true"
                                @search-change="getCustomersAutocomplete"
                                :class="{ 'is-invalid': submitted && $v.filterInput.email.$error }">
                <template slot="tag" slot-scope="{ option, remove }">
                <span class="custom__tag">
                    <span>{{ option.text }}</span>
                    <span class="custom__remove" @click="remove(option)">✖</span>
                </span>
                </template>
                </multiselect>
                <validationMessages v-if="submitted" :fieldName="'Email'" :validationName="$v.filterInput.email"></validationMessages>
            </b-form-group>
            <input type="hidden" name="csrf_token" v-model="csrf_token">
        </form>
        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addBusinessMember" :disabled="tryingToEdit">
                <b-spinner v-show="tryingToEdit" small></b-spinner>
                Submit</b-button>
        </template>
    </b-modal>
</template>
