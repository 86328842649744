<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Customers from '@/services/Customers';
    import moment from "moment";
    import modalEditCompany from "@/components/modals/customers/modalEditCompany";
    import modalAddMember from "@/components/modals/customers/modalAddCompanyMember";
    import Swal from "sweetalert2";
    import _ from 'underscore';

    export default {
        components: { Layout, PageHeader  , modalEditCompany , modalAddMember},
        page: {
            title: "Companies",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                isAuthorized: false,
                error: null,
                filterData: false,
                showMap: false,
                title: "Company Detail",
                items: [
                    {
                        text: "Companies",
                        href: "/customers/companies",
                    },
                    {
                        text: "Companies Detail",
                        active: true,
                    },
                ],
                userStatuses: [],
                companyData: {},
                members: {},
                isBusy: false,
                totalRows: 0,
                toggleProfile: false,
//                modalData: {},
                modalData: {
                    name: "",
                    website: "",
                    description: "",
                    background_color: "",
                    logo_url: null,
                    company_image: null,
                },

            };
        },
        async mounted() {
          this.getCompanies();
//          this.getBusinessMembers();
        },
        methods: {
//            async getBusinessMembers(){
//                try {
//                    this.toggleBusy();
//                    var filters = 'limit=10000&company_id='+this.$route.params.id;
//                    const response = await Customers.getBusinessMembers(filters);
//                } catch (error) {
//                    this.error = error.response.data.error ? error.response.data.error : "";
//                } finally {
//                    this.toggleBusy();
//                }
//            },
            async getCompanies(){
                try {
                    this.filterData = true;
                    this.toggleBusy();
                    var filters = 'limit=10000&company_id='+this.$route.params.id;
                    const response = await Customers.getCompanies(filters);
                    this.companyData = response.data.data[0];
                    this.members = response.data.data[0].club_members;

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                } finally {
                    this.toggleBusy();
                }
            },
            callModalCompanyEdit(data) {
                this.modalData = { ...data };
                this.$bvModal.show("company_edit");
            },
            callModalAddMembers(data) {
                this.modalData = { ...data };
                this.$bvModal.show("add_company_members");
            },
            toggle() {
                this.toggleProfile = !this.toggleProfile;
            },

            deleteCompany(company_id) {
                Swal.fire({
                    title: "Are you sure?",
                    text: "This Company will be deleted !",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!",
                }).then((result) => {
                    if (result.value) {
                        Customers.deleteCompany(company_id)
                            .then((response) => {
                                const res = response.data.success ? response.data.success : false;
                                const error = response.data.error ? response.data.error : 'Failed';
                                if(res){
                                    Swal.fire("Removed!", "Company Removed !", "success");
                                    window.location.href = "/customers/companies";
                                }else{
                                    Swal.fire("Fail!", error, "warning");
                                }
                            })
                            .catch(error => {
                                this.getCompanies();
                                Swal.fire("Fail!", error, "warning");
                            });
                    }
                });
            },

            async removeMember(customer_id) {
                Swal.fire({
                    title: "Remove this member?",
                    text: "This Member will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        try {
                            const company_id = this.$route.params.id;
                            const response = await Customers.deleteBusinessMembers(company_id, customer_id);
                            if (response.data.success) {
                                await this.getCompanies();
                                Swal.fire("Removed!", "Member is successfully removed!", "success");
                            } else {
                                const error = response.error ? response.error : 'Failed';
                                Swal.fire("Fail!", error, "warning");
                            }
                        } catch (error) {
                            this.error = error.response && error.response.data && error.response.data.error
                                ? error.response.data.error
                                : "An error occurred while removing the member.";
                            Swal.fire("Error", this.error, "error");
                        }
                    }
                });
            },


        },


        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div   v-if="!toggleProfile" class="row">
            <div class="row mb-4">
                <div class="col-xl-4">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="text-center">
                                <b-dropdown
                                        class="float-end"
                                        variant="white"
                                        right
                                        menu-class="dropdown-menu-end"
                                        toggle-class="font-size-16 text-body p-0"
                                >
                                </b-dropdown>

                                <b-dropdown
                                        class="float-end"
                                        variant="white"
                                        right
                                        menu-class="dropdown-menu-end"
                                        toggle-class="font-size-16 text-body p-0"
                                >
                                    <template v-slot:button-content>
                                        <i class="uil uil-ellipsis-v"></i>
                                    </template>
                                    <a
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            @click="callModalCompanyEdit(companyData)"
                                    >Edit</a>

                                    <a href="javascript:void(0);" class="dropdown-item" @click="deleteCompany(companyData.company_id)">
                                        Delete
                                    </a>

                                </b-dropdown>
                                <h3 class="mt-3 mb-1">
                                    {{ companyData.name }}
                                </h3>
                                <span>
                        <img :src="companyData.logo_url" style="width: 80px;" />
                    </span>
                                <a :href="companyData.website" class="nav-link">
                                    <span>{{ companyData.website }}</span>
                                </a>
                                <hr class="my-2" />
                                <p class="mt-4 mb-1" style="font-weight: bold">
                                    {{ companyData.description }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8">


                    <div class="card h-100">
                        <div class="card-body">
                            <h4 class="text-center">Members</h4>

                            <div class="col-md-12">
                                <div class="mb-3">
                                    <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light me-3"
                                       @click="callModalAddMembers(companyData)">
                                        <i class="mdi mdi-plus me-2"></i>
                                        Add Member
                                    </a>
                                </div>
                            </div>

                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>Member Image</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Phone</th>
                                    <th>LinkedIn</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="member in members" :key="member.customer_id">
                                    <td>
                                      <img :src="member.profile_url != '' ? member.profile_url : '@/assets/images/image_not_found.png' " alt="" width="50" class="img-thumbnail me-2" >
                                    </td>
                                    <td>{{ member.name }} {{ member.surname }}</td>
                                    <td>
                                      <router-link :to="{ path: `/customers/profile/${member.customer_id}`}">{{ member.email }}
                                      </router-link>
                                    </td>
                                    <td>{{ member.company_role }}</td>
                                    <td>{{ member.phone_number }}</td>
                                    <td> {{ member.linkedin_url }} </td>
                                    <td>
                                        <a href="javascript:void(0);" class="px-2 text-danger" title="Delete Members" @click="removeMember(member.customer_id)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <modalAddMember
                    :modalData="modalData"
                    @onRefresh="getCompanies"
            ></modalAddMember>
            <modalEditCompany
                    :modalData="modalData"
                    @onRefresh="getCompanies"
            ></modalEditCompany>

        </div>
    </Layout>
</template>
